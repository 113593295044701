import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
  Route,
  Navigate as Redirect,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '@iso/components/utility/loader';
import ErrorBoundary from './ErrorBoundary';
import PUBLIC_ROUTE from './route.constants';

const Dashboard = lazy(() => import('@iso/containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.ADMINISTRATOR,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(
      () => import('@iso/containers/Pages/ResetPassword/ResetPassword'),
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(
      () => import('@iso/containers/Pages/ForgotPassword/ForgotPassword'),
    ),
  },
  {
    path: PUBLIC_ROUTE.TERMS_OF_CONDITION,
    component: lazy(
      () => import('@iso/containers/Pages/TermsOfServices/TermsOfServices'),
    ),
  },
  {
    path: PUBLIC_ROUTE.PRIVACY_POLICY,
    component: lazy(
      () => import('@iso/containers/Pages/PrivacyPolicy/PrivacyPolicy'),
    ),
  },
  {
    path: PUBLIC_ROUTE.CREATE_PASSWORD,
    component: lazy(
      () => import('@iso/containers/Pages/CreatePassword/CreatePassword'),
    ),
  },
];

function PrivateRoute({ Component }) {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const uData = useSelector((state) => state.Auth.userData);
  const redPath = uData && uData.redirect ? uData.redirect : 'signin';

  return isLoggedIn ? (
    <Component />
  ) : (
    <Redirect
      to={{
        pathname: `/${redPath}`,
        state: { from: location.pathname },
      }}
    />
  );
}

export default function MainRoutes() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700); // Set a timeout of 500 milliseconds before displaying the loader

    return () => clearTimeout(timer);
  }, []);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        {loading ? (
          <Loader />
        ) : (
          <Router>
            <Routes>
              {publicRoutes.map((route) => (
                <Route
                  key={`${route.path}`}
                  path={`${route.path}`}
                  element={<route.component />}
                />
              ))}

              <Route
                key="*"
                path="*"
                element={<PrivateRoute Component={Dashboard} />}
              />
            </Routes>
          </Router>
        )}
      </Suspense>
    </ErrorBoundary>
  );
}
