/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import siteConfig from '@iso/config/site.config';
import { omitBy, isNull, isUndefined, isEmpty, isArray } from 'lodash';
import { getToken, getLanguage, getCountry } from '@iso/lib/helpers/utility';
import Notification from '@iso/components/Notification';
import authAction from '@iso/redux/auth/actions';
import appActions from '@iso/redux/app/actions';
import { store } from '@iso/redux/store';

const defaultHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

function apiCall(
  url,
  data = {},
  methodParm = 'GET',
  heads = defaultHeader,
  formData = false,
  fetchCommonData = false,
) {
  const headers = { ...heads };
  const method = methodParm.toUpperCase();
  const token = getToken();
  const lang = getLanguage();
  const country = getCountry();
  const cId = country && country.id ? country.id : '';

  if (token && !headers.Authorization)
    headers.Authorization = `Bearer ${token}`;

  headers.language = lang || 'en';

  let options = {
    method,
    headers,
  };
  let query = '';
  let qs = '';

  const apiData = !formData
    ? omitBy(data, (v) => isUndefined(v) || isNull(v))
    : data;

  if (cId && cId !== 'all') {
    // apiData.country = cId;
    headers.country = cId || '';
  }

  const dataLength = apiData ? Object.keys(apiData).length : 0;
  const body = formData ? apiData : JSON.stringify(apiData);

  if (method === 'POST' || method === 'PUT' || method === 'DELETE')
    options = { ...options, body };
  if (method === 'GET' && dataLength > 0) {
    Object.keys(apiData).map((key, i) => {
      const sep = i === dataLength - 1 ? '' : '&';
      query += `${encodeURIComponent(key)}=${encodeURIComponent(
        apiData[key],
      )}${sep}`;
    });
    qs = `?${query}`;
  }

  return new Promise((resolve, reject) => {
    fetch(`${siteConfig.apiUrl}${url}${qs}`, options)
      .then((response) => response.json())
      .then((resposeJson) => {
        if (
          resposeJson &&
          token &&
          resposeJson.code === 402
          // store
        ) {
          // const auth = store ? store.getState().Auth : {};
          // store.dispatch(authAction.setLoggedOut(true));
          Notification('error', 'Session expired, Please login again');
          // if (!auth.loggedOut) {
          setTimeout(() => {
            store.dispatch(authAction.logout());
          }, 500);
          // }
        } else {
          resolve(resposeJson);
          const cmData = store ? store.getState().App.commonData : {};
          if (fetchCommonData || isEmpty(cmData)) {
            store.dispatch(appActions.setCommonData());
          }
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function getAPIProgressData(
  endpoint,
  method,
  data,
  heads = {},
  onProgress = null,
) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const url = siteConfig.apiUrl + endpoint;
      const oReq = new XMLHttpRequest();
      oReq.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded * 100) / event.total;
          if (onProgress) {
            onProgress(progress);
          }
        }
      });

      // eslint-disable-next-line no-undef, global-require
      const FormData = require('form-data');
      const form = new FormData();
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).map((k) => {
          if (isArray(data[k]) && data[k].length > 0) {
            data[k].map((item) => {
              form.append(k, item);
            });
          } else {
            form.append(k, data[k]);
          }
        });
      }

      const headers = !isEmpty(heads)
        ? { ...heads }
        : {
            'Content-Type': 'multipart/form-data',
          };

      const token = getToken();
      if (token && !headers.Authorization)
        headers.Authorization = `Bearer ${token}`;

      const options = {
        method,
        headers,
        body: form,
      };
      console.log('options================>', options);

      delete options.headers['Content-Type'];

      // console.log("options ========================>");
      // console.log(url);
      // console.log(options);

      fetch(url, options)
        .then((res) => {
          if (res && token && res.message === 'jwt expired') {
            Notification('info', 'Session expired, Please login again');
            store.dispatch(authAction.logout());
            // }
          }
          if (
            res.status === 403 ||
            res.status === 401 ||
            res.status === 404 ||
            res.code === 403 ||
            res.code === 401 ||
            res.code === 404 ||
            res.message === 'Unauthorized'
          ) {
            // store.dispatch(authAction.clearAllData());
          } else {
            resolve(res.json());
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log('Catch Part', err);
          reject(err);
        });
    });
  }
}

export default apiCall;
